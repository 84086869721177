import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';

import Footer from '../partials/Footer';

function PageNotFound() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="relative">

          {/* Illustration behind content */}
          {/* <div className="absolute left-1/2 transform -translate-x-1/2 -mb-64 bottom-0 pointer-events-none" aria-hidden="true">
            <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
                  <stop stopColor="#FFF" offset="0%" />
                  <stop stopColor="#EAEAEA" offset="77.402%" />
                  <stop stopColor="#DFDFDF" offset="100%" />
                </linearGradient>
              </defs>
              <g fill="url(#illustration-01)" fillRule="evenodd">
                <circle cx="1232" cy="128" r="128" />
                <circle cx="155" cy="443" r="64" />
              </g>
            </svg>
          </div> */}

          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto  pb-12 md:pb-16">
                <h1 className="h1 mb-6 text-center">Terms&Privacy Policy</h1>
                <p className="text-base text-gray-600 mb-4">
                All information contained within this site is protected by Copyright law and is owned by Maonline Solutions. It is forbidden to modify, publish, transmit, transfer, sell, reproduce, display or commercially exploit this content without the express written approval of its authors.
                </p>

                <p className="text-base text-gray-600 mb-4">
                Information provided on this site is current at time of uploading. It is intended as general information only and interested parties should seek their own legal advice.                </p>
                
                <p className="text-base text-gray-600 mb-4">
                Maonline Solutions considers the protection of privacy to be vital. We endeavour to consistently maintain the security of our website for all visitors and users. This policy outlines how Maonline Solutions collects and uses personal information.                </p>

                <p className="text-base text-gray-600 mb-4">
                Other websites may be accessible from our website via hyperlinks. Our privacy policy does not cover or reflect on the procedures of these other websites. It is the responsibility of the visitor/user to evaluate the privacy policy of other websites you choose to visit.                </p>

                <p className="text-base text-gray-600 mb-4">
                Some functionalities of the website will ask for the voluntary supply of personal information. This information includes:</p>
                <p className="text-base text-gray-600 mb-1 ">•	name and email address for the purpose of subscribing to our e-newsletter; and</p>
                <p className="text-base text-gray-600 mb-4 ">•	name, email address, contact details and message for the purpose of assisting you with an enquiry to Maonline Solutions.
</p>

<p className="text-base text-gray-600 mb-4">
Maonline Solutions does not collect information for direct marketing purposes, except where you have given permission by subscribing to our e-newsletter. This information is not sold to other parties and is for the express purpose of our own communication with you. At any time you can opt out by clicking unsubscribe on the e-newsletter that you receive.                </p>

<p className="text-base text-gray-600 mb-4">
Other than the above stated, Maonline Solutions will not attempt to identify users or their browsing activities except</p>                <p className="text-base text-gray-600 mb-1 ">•	in the event of an investigation by law enforcement: or</p>
                <p className="text-base text-gray-600 mb-4 ">•	where we believe we are required to collect and produce documentation relevant to fulfil obligations to third parties; including statutory organisations under local law.
</p>

<p className="text-base text-gray-600 mb-4">
In the course of providing services you request, personal information may be shared: with authorities; third parties engaged by Maonline Solutions to provide services to you; to protect intellectual property rights; or to enforce the Terms and Conditions of the website.                </p>
                <div className="mt-8 text-center">
                  <Link to="/" className="btn text-white bg-blue-600 hover:bg-blue-700">Go back home</Link>
                </div>
              </div>
              {/* <div className="max-w-3xl mx-auto text-center"> */}
                {/* 404 content */}
                {/* <h1 className="h1 mb-4">Oh, No! You stumbled upon a rarity</h1>
                <div className="mt-8">
                  <Link to="/" className="btn text-white bg-blue-600 hover:bg-blue-700">Go back home</Link>
                </div>
              </div> */}
            </div>
          </div>

        </section>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default PageNotFound;