import React from 'react';

function AboutStory() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          <div className="max-w-3xl mx-auto">
            <h3 className="h3 mb-3">About Maonline Solutions</h3>
            <p className="text-lg text-gray-600 mb-8">
              Maonline Solutions was formed in 2015 with the aim of providing quality IT Managed Services at a competitive price, primarily with the NGO sector in mind. We have continually proven to be an attractive IT Service Provider to NGO’s for not only our proactive IT Managed Service delivery, responsive and efficient project delivery, but implementing quality customised and secure SharePoint projects, which are on-time and often under budget.            </p>
            <p className="text-lg text-gray-600 mb-8">
            Maonline Solutions internally employs some of most hardened and sophisticated security measures to ensure customer information is kept secure and private. All passwords and customer documentation are kept in an SOC 2 certified datacentre that requires Two-Factor authentication to access. All Maonline Solutions PC’s and Servers are hardware encrypted and data is stored within Australian Microsoft Data Centres with Advanced Threat Protection.             </p>
            <p className="text-lg text-gray-600 mb-8">
            Maonline Solutions is a Microsoft Certified Partner, and a recognised Partner and Distributor with many other prominent Technology Vendors including HP, AWS, Barracuda, Datto, Checkpoint, Sophos, Aruba, ESET, Intel, and IBM etc.            </p>
          </div>

          <div className="sm:flex">
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/Granular-Reporting_337446977.jpg')} width="435" height="326" alt="About us 02" />
            </figure>
            <div className="sm:ml-8 lg:ml-16">
              <h4 className="h4 mb-4">IT Managed Services</h4>
              <p className="text-base text-gray-600 mb-6">
              •	Provide regular and scheduled updates for supported Microsoft Windows and Office, and 3rd Party software (e.g., Chrome, Acrobat, Java, MYOB etc)              </p>
              <p className="text-base text-gray-600 mb-6">             
              •	Provide updates and maintenance of Antivirus or Endpoint Security Solutions</p>
              <p className="text-base text-gray-600 mb-6">
              • Basic Administration of Office 365 Tenant.Customer Ticketing system for IT service faults and Project Requests. 24/7/365 Monitoring all critical IT Infrastructure.               </p>
              {/* <div className="flex  mb-8">
                <img className="rounded-full self-start flex-shrink-0 shadow-lg mr-4" src={require('../images/about-logo.png')} width="40" height="40" alt="Logo" />
                <div>
                  <blockquote className="text-gray-600 italic">
                    “ I love this product and would recommend it to anyone. Could be not easier to use, and our multiple websites are wonderful. We get nice comments all the time. “
                  </blockquote>
                  <div className="text-sm font-medium text-gray-600 mt-2">
                    <cite className="text-gray-900 not-italic">Micheal Osman</cite> · <a className="text-blue-600" href="#0">New York Times</a>
                  </div>
                </div>
              </div> */}
              
            </div>
          </div>

          <div className="max-w-3xl mx-auto mt-8">
            <h4 className="h4 mb-4">IT Managed Security Services</h4>
              <p className="text-base text-gray-600 mb-4">
              •	Advanced cybersecurity implementation and management of Security Appliances, like Firewall’s or UTM’s, Wireless Appliances, and network switches.              </p>
              <p className="text-base text-gray-600 mb-4">             
              •24/7/365 proactive management, alerting and remediation</p>
              <p className="text-base text-gray-600 mb-4">
              • Integrated Endpoint Antivirus solutions that are proactive managed              </p>

            <h4 className="h4 mb-4">IT Managed Services for Office 365</h4>
              <p className="text-base text-gray-600 mb-4">
              •	Implement Office 365 tenants, including Domain registration, Licencing options             </p>
              <p className="text-base text-gray-600 mb-4">             
              •	Design and Implement Office 365 Advanced Threat Protection and Information Rights Management </p>
              <p className="text-base text-gray-600 mb-4">
              •	Advanced Design of SharePoint Online sites and projects.      </p>

             <h4 className="h4 mb-4">IT Managed Services Business Continuity</h4>
              <p className="text-base text-gray-600 mb-4">
              • Provide Business Continuity and Backup solutions for all Onsite Servers with continuous replication to offsite datacentre.               </p>
              <p className="text-base text-gray-600 mb-4">             
              •	Email Security with secondary backup relay. </p>
              <p className="text-base text-gray-600 mb-4">
              • Advanced email backup and recovery, including litigation hold and e-discovery </p>

              <h4 className="h4 mb-4">Website Development</h4>
              <p className="text-base text-gray-600 mb-6">
              • Develop websites for our customers which can help them demonstrate their products and services better</p>
                             
            {/* <p className="text-lg text-gray-600">
              Aenean sed adipiscing diam donec adipiscing tristique risus nec feugiat auctor urna nunc id cursus metus aliquam eleifend, arcu dictum varius duis at consectetur lorem donec massa sapien, sed risus ultricies tristique nulla aliquet. Morbi tristique senectus et netus et, nibh nisl condimentum id venenatis a condimentum vitae sapien.
            </p> */}
          </div>

        </div>
      </div>
    </section>
  );
}

export default AboutStory;